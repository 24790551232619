import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    infoStudentExpired: {},
    examinationRooms: [],
    infoReception: {},
    avatar: '',
  },
  getters: {
    examinationRooms: state => state.examinationRooms,
    infoStudentExpired: state => state.infoStudentExpired,
    infoReception: state => state.infoReception,
    avatar: state => state.avatar,
  },
  mutations: {
    SET_INFO_STUDENT_EXPIRED: (state, infoStudentExpired) => { state.infoStudentExpired = infoStudentExpired },
    SET_EXAMINATION_ROOMS: (state, examinationRooms) => { state.examinationRooms = examinationRooms },
    SET_RECEPTION: (state, value) => { state.infoReception = value },
    SET_AVATAR: (state, data) => { state.avatar = data },
  },

  actions: {
    async getListExamination({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getListExamination', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EXAMINATION_ROOMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async uploadAvatar({ commit }, params) {
      try {
        const response = await axios.post('uni/students/uploadAvatar', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async changePassword({ commit }, { id, body }) {
      try {
        const response = await axios.put(`uni/accounts/${id}/changePassword`, body)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getInfoStudentAboutToExpired({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getInfoStudentAboutToExpired', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_INFO_STUDENT_EXPIRED', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getInfoReception({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportClassReceiptCourseSemester', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RECEPTION', data.data || {})
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAvatarById({ commit }, id) {
      try {
        const response = await axios.get(`uni/students/${id}/avatar`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_AVATAR', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
